<template>
  <master-layout
    smallTitle="Questa settimana"
    bigTitle="Clienti Caldi"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-grid class="main-container">
      <!-- <ion-row
        class="items ion-padding-horizontal ion-margin-bottom ion-margin-top ion-margin-top"
        v-for="item of filteredAziende"
        :key="item.id"
        @click="openModalActualClient(item)"
      > -->
      <ion-row
        class="items ion-padding-horizontal ion-margin-bottom ion-margin-top ion-margin-top button-primary border-radius-client py-2 ion-padding-top"
        v-for="item of filteredAziende"
        :key="item.id"
        @click="gotoDettagliCliente(item)"
      >
        <ion-col>
          <ion-row> {{ item.ragioneSociale }} </ion-row>
          <ion-row>
            <ion-col>
              <gradient-progress-bar
                class="height-5 gradient"
                :value="item.hotness"
              ></gradient-progress-bar>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col class="flex justify-content-end align-items-center" size="2">
          <ion-icon :icon="call"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row class="cta ion-justify-content-center"
      ><ion-col size="auto">
        <button
          :disabled="loadingMoreResults"
          type="button"
          class="p-d button-primary"
          @click="askForMoreResults"
        >
          {{ loadingMoreResults ? "Caricamento..." : "Carica più risultati" }}
        </button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
import {
  modalController,
  IonButton,
  IonFooter,
  IonToolbar,
  IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/vue"
import { call } from "ionicons/icons"
import ModalActualClient from "./Modals/ModalActualClient.vue"
import GradientProgressBar from "@/components/GradientProgressBar.vue"
import ApiService from "../common/service.api"
export default {
  name: "AnagraficaClienti",
  components: {
    IonButton,
    IonFooter,
    IonToolbar,
    IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    GradientProgressBar,
  },
  data() {
    return {
      call,
      filter: "",
      items: [],
      objectToPost: {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
      },
      INFOPOPOVER_MAP: {
        Yoube:
          "Una selezione dinamica dei tuoi clienti più interessati al brand. Per non perderti delle opportunità.",
        default:
          "Una selezione dinamica dei tuoi clienti più interessati al brand. Per non perderti delle opportunità.",
      },
      currentResults: 0,
      loadingMoreResults: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      ApiService.post("Ecommerce/GetClientsHot/", {
        take: 10,
        skip: this.currentResults,
      })
        .then((res) => {
          this.items = res.data
        })
        .finally(() => {
          this.currentResults = this.items.length
        })
    },
    askForMoreResults() {
      const endpoint = "Ecommerce/GetClientsHot/"
      const queryParams = {
        take: 10,
        skip: this.currentResults,
      }

      this.loadingMoreResults = true

      ApiService.post(endpoint, queryParams)
        .then((response) => {
          this.items = this.items.concat(response.data)
        })
        .finally(() => {
          this.currentResults = this.items.length
          this.loadingMoreResults = false
        })
    },
    gotoDettagliCliente(cliente) {
      console.log(
        "🚀 ~ file: AnagraficaClienti.vue:152 ~ gotoDettagliCliente ~ cliente:",
        cliente
      )

      this.$router.push({
        name: "DettagliCliente",
        params: {
          idCliente: cliente.id,
        },
      })
    },
    async openModalActualClient(item) {
      const modal = await modalController.create({
        component: ModalActualClient,
        cssClass: "my-custom-class",
        componentProps: { client: item },
      })

      return modal.present()
    },
  },
  computed: {
    filteredAziende() {
      if (this.filter) {
        return this.items.filter((azienda) => {
          return (
            azienda.nome.toLowerCase().includes(this.filter.toLowerCase()) ||
            azienda.ragioneSociale
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            azienda.cognome.toLowerCase().includes(this.filter.toLowerCase())
          )
        })
      } else {
        return this.items
      }
    },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid purple;
} */

/* .cta {
  padding: 2rem 0;
}

.cta ion-button {
  height: 4rem;
} */

h3 {
  font-weight: bolder;
}
</style>
